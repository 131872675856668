import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { getAirfield, getAirfieldBySlug } from './API';
import AirfieldCard from './AirfieldCard';
import MiniMap from './MiniMap.js';
import { useNavigate } from "react-router-dom";

const AirfieldPage = props => {
  const { slug } = useParams();
  const [airfield, setAirfield] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    // Don't load anything until there's a valid ID
    // TODO: why is there a valid ID - does ID need to be stored?
    // TODO: need to show a loading screen until this is valid, and on any change
    if (slug) {
        getAirfieldBySlug(slug).then(data => {
          if(data) {
            setAirfield(data);
          } else {
            // Try again with ID instead of slug
            getAirfield(slug).then(data => {
              setAirfield(data);
            });
          }
        });
      }
    }, [slug]);

    const handleOnSelectAirfield = s => {
      console.log('handleOnSelectAirfield', s)
      // TODO: navigate or update url?
      //setAirfield(slug);
      //slug = s;
      navigate(s); // TODO: this doesn't go to the right level
    };

  return (
    <div>
      <div className="card-container">
        <AirfieldCard airfield={airfield} />
      </div>
      <div className="minimap-container">
        <MiniMap airfield={airfield} id='map-overview' onSelectAirfield={handleOnSelectAirfield} />
        <MiniMap airfield={airfield} id='map-detail' />
      </div>
    </div>
  );
};

export default AirfieldPage;
