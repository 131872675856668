import React from 'react';
import { Card } from 'react-bootstrap';
import CovidEditForm from './CovidEditForm.js';

const AirfieldEditCard = props => {
  return props.airfield ? (
    <Card>
      <Card.Header>
        {props.airfield.icao}{' '}
        <strong>
          {Object.entries(props.airfield).length === 0 &&
          props.airfield.constructor === Object
            ? '(none)'
            : props.airfield.name}
        </strong>
      </Card.Header>
      <Card.Body>
        <CovidEditForm {...props} />
      </Card.Body>
    </Card>
  ) : (
    <Card>
      <Card.Header>Nothing selected</Card.Header>
      <Card.Body></Card.Body>
    </Card>
  );
};

export default AirfieldEditCard;
