import React, { useRef } from 'react';
import {
  MapContainer,
  TileLayer,
  LayersControl,
  LayerGroup,
  Marker,
  useMapEvents,
} from 'react-leaflet';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
//import Search from "react-leaflet-search";
// TODO: TEMP: FIXME: Remove https://github.com/tumerorkun/react-leaflet-search as it doesn't like react-leaflet v3
// Consider an alternative https://stackoverflow.com/questions/66043234/search-box-implementation-in-react-leaflet-v3-1-0
// Such as https://github.com/smeijer/leaflet-geosearch
// See also https://stackoverflow.com/questions/48290555/react-leaflet-search-box-implementation

import { iconByType } from './Icons';
//import { search } from './API';

const mapMarkerIconTypes = Object.freeze({
  airfield: 0,
  covid_status: 1,
  last: 2
});

// TODO: seperate map CSS from App CSS

function MapEvents() {
  const navigate = useNavigate();

  const updateUrl = (e) => {
    const LATLNG_DIGITS = 5;
    const lat = map.getCenter().lat;
    const lng = map.getCenter().lng;
    const zoom = map.getZoom();
    const loc = `#loc=${lat.toFixed(LATLNG_DIGITS)}/${lng.toFixed(LATLNG_DIGITS)}/${zoom}`;
    navigate(loc); // push 
  }

  const mapMarkerStyle = useRef(); // style according to zoom level
  const map = useMapEvents({
    locationfound(e) {
      map.flyTo(e.latlng, map.getZoom());
    },
    move(e) {
      //map.getCenter();
      // TODO: this seems to be re-rendering
      updateUrl(e);
    },
    moveend(e) {
      updateUrl(e);
    },
    zoomend(e) {
      var mapZoom = map.getZoom();
      var zoom = 2.5 * mapZoom;
  
      // TODO: should probably select a specific stylesheet rather than just the first one
      const stylesheet = document.styleSheets[0];
      if (mapMarkerStyle.current !== undefined) {
        stylesheet.deleteRule(mapMarkerStyle.current);
      }
      mapMarkerStyle.current = stylesheet.insertRule(
        '.mapMarker{height: ' + zoom + '; width:' + zoom + 'px;}'
      );
    }
  });
  map.locate();
  return null;
}

function AirfieldMap(props) {
  const { BaseLayer, Overlay } = LayersControl;

  // Almost all of the state shouldn't re-render, as it's mostly managed
  // inside the Leaflet Map

  const handleMarkerClick = (event, id) => {
    props.onSelect(id);
  };

  const covidIcon = airfield => {
    var icon = 'covid_unknown';
    if ('covid' in airfield) {
      icon = airfield.covid.status;
    }
    return iconByType(icon);
  };

  const getIcon = airfield => {
    if (props.iconType === mapMarkerIconTypes.airfield) {
      return covidIcon(airfield);
    } else {
      return iconByType(airfield.aeroway);
    }
  };

  const humanitarianTiles = '//a.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png';
  const humanitarianAttr =
    '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';
  const stamenTerrainBackgroundTiles =
    '//stamen-tiles-{s}.a.ssl.fastly.net/terrain-background/{z}/{x}/{y}.png';
  const stamenTerrainBackgroundAttr =
    'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>';
  const iconsAttr = ' | <a href="/attribution">Icons attribution</a>';

  /*const customProvider = {
    search: async (inputValue) => {
      var res = await search(inputValue);
      var _info = res.map(x => {
        var i = x['item'];
        var lat = i.centroid.coordinates[1];
        var lng = i.centroid.coordinates[0];
        return {
          bounds: [lat, lat, lng, lng],
          latitude: lat,
          longitude: lng,
          name: i.name
        };
      });
      var _raw = res.map(x => { return x['item'] });
      return {
        info: _info,
        raw: _raw
      };
    }
  }*/

  let location = useLocation();
  let params = new URLSearchParams(location.hash.substring(1));
  let loc = params.get("loc"); // is the string "Jonathan"
  var lat;
  var lng;
  var zoom;

  if(loc) {
    [lat, lng, zoom] = loc.split("/");
    lat = parseFloat(lat);
    lng = parseFloat(lng);
    zoom = parseFloat(zoom);  
  } else {
    // Default position, if not specified in URL
    lat = 55;
    lng = -3;
    zoom = 6;  
  }

  console.log("loc: " + location.hash + " >> " + lat + "*" + lng + " z:" + zoom);

  // default location and zoom shows the bulk of the UK, on my screen at least
  return (
    <MapContainer
      id='map'
      className='map'
      center={[lat, lng]}
      zoom={zoom}
    >
      <MapEvents />
      <LayersControl position='bottomright'>
        <BaseLayer checked name='OSM Humanitarian'>
          <TileLayer
            attribution={humanitarianAttr + iconsAttr}
            url={humanitarianTiles}
          />
        </BaseLayer>
        <BaseLayer name='Stamen Terrain Background'>
          <TileLayer
            attribution={stamenTerrainBackgroundAttr + iconsAttr}
            url={stamenTerrainBackgroundTiles}
          />
        </BaseLayer>

        <Overlay checked name='Airfields'>
          <LayerGroup>
            {props.airfields.map(airfield => {
              if ('centroid' in airfield) {
                return (
                  <Marker
                    key={airfield._id}
                    position={{
                      lng: airfield.centroid.coordinates[0],
                      lat: airfield.centroid.coordinates[1]
                    }} // TODO: consider switching this to GeoJSON?
                    title={airfield.name}
                    icon={getIcon(airfield).leaflet_icon}
                    eventHandlers={{
                    click: (event) => {
                      handleMarkerClick(event, airfield._id)
                    }}}
                  ></Marker>
                );
              } else {
                console.log('Missing location for', airfield);
                return null;
              }
            })}
          </LayerGroup>
        </Overlay>
      </LayersControl>
    </MapContainer>
  );
}

// TODO: FIXME: fix search

  /*<Search
    position="topleft"
    provider="OpenStreetMap"
    providerOptions={{ region: "gb" }}
    showPopup={false}
    showMarker={false}
    closeResultsOnClick={true}
    customProvider={customProvider}
  />*/

export { mapMarkerIconTypes };
export default AirfieldMap;

// TODO: apply https://stackoverflow.com/questions/62182987/is-the-react-way-really-to-re-render-the-whole-react-leaflet-component-regular/62234666#62234666
