import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import AirfieldMap, { mapMarkerIconTypes } from './Map';
import AirfieldPage from './AirfieldPage';
import Sidebar from './Sidebar';
import Key from './Key';
import Attribution from './Attribution';

import { getAirfields } from './API';
import './App.css';

import Userfront from '@userfront/react';
import AirfieldEditPage from './AirfieldEditPage';
import ListAirfields from './ListAirfields';

Userfront.init("6bg9j6b7");

const SignupForm = Userfront.build({
  toolId: "naanam",
});
const LoginForm = Userfront.build({
  toolId: "mnnmnm",
});
const LogoutForm = Userfront.build({
  toolId: "aaamad",
});
const PasswordResetForm = Userfront.build({
  toolId: "drrlro",
});

function Dashboard() {
  function renderFn({ location }) {
    // If the user is not logged in, redirect to login
    if (!Userfront.accessToken()) {
      return (
        <Navigate
          to={{
            pathname: "/login",
            state: { from: location },
          }}
        />
      );
    }

    // If the user is logged in, show the dashboard
    const data = JSON.stringify(Userfront, null, 2);
    const userData = JSON.stringify(Userfront.user, null, 2);
    const at = JSON.stringify(Userfront.accessToken(), null, 2);
    const it = JSON.stringify(Userfront.idToken(), null, 2);
    return (
      <div>
        <h2>Dashboard</h2>
        <pre>data: {data}</pre>
        <pre>user data: {userData}</pre>
        <pre>at: {at}</pre>
        <pre>it: {it}</pre>
        <button onClick={Userfront.logout}>Logout</button>
      </div>
    );
  }
  
  return <Route render={renderFn} />;
}

function Map() {
  const [airfields, setAirfields] = useState([]);
  const [airfieldId, setAirfieldId] = useState(null);
  const mapRef = useRef();
  const [mapIconType, setMapIconType] = useState(
    mapMarkerIconTypes.covid_status
  );

  useEffect(() => {
    getAirfields().then(a => {
      // Note: this will result in a re-rendering, but it only happens once on first load
      setAirfields(a);
    });
  }, []);

  // Poor man's toggling of airfield status and type
  // TODO: replace this with status circles around airfield type
  // or a more extensive option to change the icon displayed
  const covidBtn1 = 'Show airfield type';
  const covidBtn2 = 'Show COVID-19 status';
  const handleToggleIconStyle = () => {
    setMapIconType((mapIconType + 1) % mapMarkerIconTypes.last);
    var btn = document.getElementById('toggleButton');
    btn.innerText = btn.innerText === covidBtn1 ? covidBtn2 : covidBtn1;
  };

  const handleSave = airfield => {
    getAirfields().then(a => {
      // Note: this seems rather wasteful just to make sure the icon is updated,
      // but it only happens when the user updates an entry which is fairly rare.
      setAirfields(a);
    });
  };

  const handleOnSelect = id => {
    setAirfieldId(id);
  };

  return (
    <div className='map'>
      <AirfieldMap
        innerRef={mapRef}
        iconType={mapIconType}
        onSelect={handleOnSelect}
        airfields={airfields}
      />
      <div className='title'>
        <Button
          id='toggleButton'
          onClick={handleToggleIconStyle}
          color='primary'
        >
          {covidBtn1}
        </Button>
      </div>
      <Sidebar id={airfieldId} onSave={handleSave} />
      <Key />
    </div>
  );
}

// TODO: better 404 page
const NoMatch = () => {
  return (<h1>404: page not found</h1>);
};

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Navigate to="map" replace />} />
        <Route path='map/*' element={<Map />} />

        <Route path='/a/:slug' element={<AirfieldPage />} />
        <Route path='/edit/:slug' element={<AirfieldEditPage />} />
        <Route path='/attribution' element={<Attribution />} />
        <Route path='/list' element={<ListAirfields />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/logout" element={<LogoutForm />} />
        <Route path="/reset" element={<PasswordResetForm />} />
        <Route path="/signup" element={<SignupForm />} />
        <Route path="/dashboard"  element={<Dashboard />} />

        <Route path="*" element={<NoMatch />} />

      </Routes>
    </BrowserRouter>
  );
}
