import React, { useState } from 'react';

import { Card, Collapse } from 'react-bootstrap';
import { FaWindowMinimize } from 'react-icons/fa';

import Icons from './Icons';

const Key = () => {
  const [open, setOpen] = useState(false);

  const createTable = () => {
    return Icons.filter(icon => icon.showInKey).map(icon => {
      return (
        <tr key={icon.type ? icon.type : ''}>
          <td>
            <img className='keyIcon' src={icon.image} alt={icon.caption} />
          </td>
          <td>{icon.caption}</td>
        </tr>
      );
    });
  };

  return (
    <div className='key'>
      <Card>
        <Card.Header onClick={() => setOpen(!open)}>
          Map key &nbsp;
          <span className='float-right'>
            <FaWindowMinimize size='0.4em' />
          </span>
        </Card.Header>
        <Collapse in={open}>
          <Card.Body>
            <table>
              <tbody>{createTable()}</tbody>
            </table>
          </Card.Body>
        </Collapse>
      </Card>
    </div>
  );
};

export default Key;
