import React from 'react';
import { Button, Card, Image } from 'react-bootstrap';
//import { linkIfURL } from './helper';
import { amenityByType } from './Amenities';

// TODO: these need moving to Icons really so that they can be attributed
import IconAirfieldCards from './icons/link_airfieldcards.ico';
import IconOpenStreetMap from './icons/link_openstreetmap.ico';
import IconUKGA from './icons/link_ukga.ico';
import IconWikidata from './icons/link_wikidata.ico';
import IconWikipedia from './icons/link_wikipedia.ico';
import IconFacebook from './icons/link_facebook.ico';

import FuelMogas from './icons/fuel_mogas.svg';
import FuelUL91 from './icons/fuel_ul91.svg';
import FuelLL100 from './icons/fuel_ll100.svg';
import FuelJetA1 from './icons/fuel_jeta1.svg';

import Userfront from "@userfront/react";

const fuelTypes = [
  {
    type: 'mogas',
    name: 'MOGAS',
    src: FuelMogas
  },
  {
    type: 'ul91',
    name: 'UL91',
    src: FuelUL91
  },
  {
    type: 'll100',
    name: 'LL100',
    src: FuelLL100
  },
  {
    type: 'jeta1',
    name: 'JET A1',
    src: FuelJetA1
  }
];

// Find fuel by type
const fuelByType = type => {
  return fuelTypes.find(fuel => {
    return fuel.type === type;
  });
};

const Fuel = props => {
  if (props.fuel) {
    return (
      <div>
        {props.fuel.map(type => {
          const fuel = fuelByType(type);
          return (
            <div key={type}>
              <Image src={fuel.src} alt={fuel.name} className='fuel' />
              &nbsp;{fuel.name} 
            </div>
          );
        })}
      </div>
    );
  } else {
    return '';
  }
};

const Amenities = props => {
  if (props.amenities) {
    return (
      <div>
        {props.amenities.map(amenity => {
          var a = amenityByType(amenity.type);
          if(!a) {
            a = amenityByType('generic');
          }
          return (
            <div key={amenity.name}>
              <a href={amenity.href} target='_new'>
                <Image src={a.icon.image} alt={a.name} className='linkButton' />
                {amenity.name}
              </a>
            </div>
          );
        })}
      </div>
    );
  } else {
    return '';
  }
};

// TODO: Link runways to OSM runways and draw them on the map
const Runways = props => {
  if(props.runways) {
    return (
      <div>
        {props.runways.map(runway => {
          return (
            <div key={runway.name}>{runway.name} - {runway.length} x {runway.width} - {runway.surface}</div>
          );
        })}
      </div>
    );
  }
};

const ExternalLinks = [
  {
    type: 'airfieldcards',
    src: IconAirfieldCards,
    alt: 'Airfield Cards',
    href: link => {
      return 'http://www.airfieldcards.com/index.php?mi=' + link.id;
    }
  },
  {
    type: 'openstreetmap',
    src: IconOpenStreetMap,
    alt: 'OpenStreetMap',
    href: link => {
      return 'https://openstreetmap.org/' + link.key + '/' + link.id;
    }
  },
  {
    type: 'ukga',
    src: IconUKGA,
    alt: 'UKGA',
    href: link => {
      return 'https://ukga.com/airfield/' + link.slug;
    }
  },
  {
    type: 'wikidata',
    src: IconWikidata,
    alt: 'Wikidata',
    href: link => {
      return 'https://wikidata.org/wiki/' + link.page;
    }
  },
  {
    type: 'wikipedia',
    src: IconWikipedia,
    alt: 'Wikipedia',
    href: link => {
      return 'https://wikipedia.org/wiki/' + link.page;
    }
  },
  {
    type: 'facebook',
    src: IconFacebook,
    alt: 'Facebook'
  }
];

// Find external link by type value
const extLinkByType = type => {
  return ExternalLinks.find(extLink => {
    return extLink.type === type;
  });
};

const Links = props => {
  if (props.links) {
    return (
      <div>
        {props.links.map(link => {
          const extLink = extLinkByType(link.type);
          var href = link.href;
          if (extLink.href) {
            href = extLink.href(link);
          }
          return (
            <div key={extLink.type}>
              <a href={href} target='_new'>
                <Image
                  src={extLink.src}
                  alt={extLink.alt}
                  className='linkButton'
                />
                 
                {extLink.alt}
              </a>
            </div>
          );
        })}
      </div>
    );
  } else {
    return '';
  }
};

/*const sourceBlock = props => {
  if (props) {
    return (
      <small>
        Info from {linkIfURL(props.source)} submitted by {props.user} on{' '}
        {props.date}
      </small>
    );
  } else {
    return <div></div>;
  }
};*/

/*const Covid = props => {
  var status = '';
  var info = '';
  if (props.covid) {
    ({ status, info } = props.covid);
  }
  var icon = iconByType(status);

  // TODO: fix height bodge
  return (
    <div className='card'>
      <div className='card-header card-covid'>
        <b>COVID-19 status:&nbsp;</b>
        <img src={icon.image} height='28px' alt={icon.caption} />
        &nbsp;
        {icon.caption}
      </div>

      {props.covid ? (
        <div className='card-body card-covid'>
          <p className='p-covid-card'>{info}</p>
          <p className='p-covid-card'>{sourceBlock(props.covid)}</p>
        </div>
      ) : (
        <div className='card-body card-covid'>No COVID information</div>
      )}
    </div>
  );
};*/

const BasicData = props => {
  const mapping = [
    {
      'key': 'ele',
      'name': 'Elevation',
      'value': props.airfield.ele * 3 + ' ft'
    },
    {
      'key': 'icao',
      'name': 'ICAO',
    },
    {
      'key': 'iata',
      'name': 'IATA',
    },
    {
      key: 'fuel',
      name: 'Fuel',
      value: <Fuel fuel={props.airfield.fuel} />
    },
    {
      'key': 'operator',
      'name': 'Operator',
    },
    {
      'key': 'phone',
      'name': 'Telephone',
      'value': props.airfield.phone,
      'link': 'tel:' + props.airfield.phone
    },
    {
      key: 'website',
      name: 'Website',
      value: props.airfield.website, // TODO: would be better to name here, but it'll do for now
      link: props.airfield.website
    },
    {
      key: 'runways',
      name: 'Runways',
      value: <Runways runways={props.airfield.runways} />
    },
    {
      key: 'amenities',
      name: 'Amenities',
      value: <Amenities amenities={props.airfield.amenities} />
    },
    {
      key: 'links',
      name: 'External links',
      value: <Links links={props.airfield.links} />
    },
    /*{
      key: 'ppr',
      name: 'PPR',
      value: props.airfield.ppr.description,
      link: props.airfield.ppr.href
    }*/
    // TODO: VHF name and frequency
    // TODO: opening hours
    // TODO: camping - yes; no
  ];

  // TODO: show (perhaps only when admin/logged in) any other tags

  var rows = mapping.map( (i) => {
    if(i.key in props.airfield) {
      var value;
      if('value' in i) {
        value = i.value;
      } else {
        value = props.airfield[i.key];
      }
      if(i.link) {
        value = <a
          target='_blank'
          rel='noopener noreferrer'
          href={i.link}>{i.value}</a>
      }

      return (
        <tr key={i.key}>
          <td key={i.key + '-name'}>{i.name}</td>
          <td key={i.key + '-value'}>{ value }</td>
        </tr>
      )
    } else {
      return null;
    }
  });

  return (
    <>
    <table className="table table-striped table-nonfluid">
      <tbody>
        {rows}
      </tbody>
    </table>
    </>
  );
};

const LoginEditLink = props => {
  if(Userfront.accessToken()) {
    const ref = props.airfield.slug !== undefined ? props.airfield.slug : props.airfield._id;
    // TODO: need to change Edit link to View when already editing
    return (
      <div>
        <Button type="button" className="btn btn-primary" href={'/edit/' + ref}>Edit</Button>
        &nbsp;
        <Button type="button" className="btn btn-danger" onClick={Userfront.logout}>Logout</Button>
      </div>
    );
  }
  else
  {
    return (
      <div><a href='/login'>Login</a></div>
    );
  }
}

const AirfieldCard = props => {
  // revert to UUID if no slug
  if (props.airfield) {
    return (
      <Card>
        <Card.Header>
          {props.airfield.icao}{' '}
          <strong>
            {Object.entries(props.airfield).length === 0 &&
            props.airfield.constructor === Object
              ? '(none)'
              : props.airfield.name}
          </strong>
          {props.airfield.iata ? ' (' + props.airfield.iata + ')' : ''}
        </Card.Header>
        <Card.Body>
          <BasicData airfield={props.airfield} />
          <LoginEditLink airfield={props.airfield}/>
        </Card.Body>
      </Card>
    );
  } else {
    return (
      <Card>
        <Card.Header>Nothing selected</Card.Header>
        <Card.Body></Card.Body>
      </Card>
    );
  }
};

export default AirfieldCard;

