import React, { useState, useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { getAirfield, updateAirfield } from './API';
import AirfieldCard from './AirfieldCard';
import AirfieldEditCard from './AirfieldEditCard.js';

const Sidebar = props => {
  // TODO: Is Sidebar the right place to hold the airfield data, or should it
  // be raised higher?
  const [airfield, setAirfield] = useState();

  useEffect(() => {
    // Don't load anything until there's a valid ID
    // TODO: why is there a valid ID - does ID need to be stored?
    // TODO: need to show a loading screen until this is valid, and on any change
    if (props.id) {
      getAirfield(props.id).then(data => {
        setAirfield(data);
      });
    }
  }, [props.id]);

  // TODO: this assumes the child has provided an update including the ID,
  // it may be better for this to be dnoe here instead.
  const handleSave = updateData => {
    return updateAirfield(updateData).then(result => {
      // Update this airfield's raw data
      setAirfield(result);
      // Tell parent data has changed (to cause map refresh)
      props.onSave();
    });
  };

  return (
    <div className='sidebar'>
      <Tabs defaultActiveKey="airfield" id="uncontrolled-tab-example">
        <Tab eventKey="airfield" title="Airfield">
          <AirfieldCard airfield={airfield} />
        </Tab>
        <Tab eventKey="edit" title="Edit">
          <AirfieldEditCard
                airfield={airfield}
                onSave={handleSave}
              />
        </Tab>
      </Tabs>
    </div>
  );
};

export default Sidebar;
