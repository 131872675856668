import React from 'react';
import { getAirfields } from './API';
import { useEffect, useState } from 'react';
import { iconByType } from './Icons';

export const ListAirfields = () => {
  const [airfields, setAirfields] = useState([]);

  const sortByName = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {

    getAirfields().then(afs => {
      // Note: this will result in a re-rendering, but it only happens once on first load
      setAirfields(afs.sort(sortByName));
      }, []
    );
  });

  return (
    <>
      <h1>Airfield Map - List of airfields</h1>
      <table className='table table-striped table-nonfluid'>
        <tbody>
          {airfields.map(airfield => {
            const icon = iconByType(airfield.aeroway);
            return (
              <tr key={airfield.id}>
                <td>
                  <img className='keyIcon' src={icon.image} alt={icon.caption} />
                </td>
                <td>
                  <a href={'/a/' + (airfield.slug ? airfield.slug : airfield._id)}>{airfield.name}</a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default ListAirfields;


