import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'react-bootstrap';
import ButtonLoader from 'react-bootstrap-button-loader';

const covidStates = [
  { value: 'covid_open', label: 'Fully open for business' },
  { value: 'covid_restricted', label: 'Restricted operations' },
  { value: 'covid_closed', label: 'Closed' },
  { value: 'covid_unknown', label: 'Unknown' }
];

const CovidEditForm = ({ airfield = {}, onSave }) => {
  const { register, handleSubmit, formState, reset } = useForm();
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState();
  const { isDirty } = formState;

  const onSubmit = data => {
    setError('');
    setSaving(true);
    if (onSave) {
      onSave({
        _id: airfield._id,
        covid: data
      })
        .then(result => {
          setError('');
        })
        .catch(e => {
          setError('Server error, your changes may or may not have saved.');
        })
        .finally(() => {
          setSaving(false);
        });
    }
  };

  const initForm = useCallback(() => {
    if ('covid' in airfield) {
      const c = airfield.covid;
      reset({
        info: c.info || '',
        status: c.status || 'covid_unknown',
        source: c.source || '',
        user: c.user || ''
      });
    } else {
      reset({
        info: '',
        status: 'covid_unknown',
        source: '',
        user: ''
      });
    }
  }, [airfield, reset]);

  // TODO: Should check !dirty before allowing change to another airfield
  useEffect(() => {
    initForm();
  }, [initForm]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <b>Update COVID-19 status</b>
      </div>
      <Form.Group controlId='status'>
        <Form.Label>Status:</Form.Label>
        <Form.Control as='select' name='status' {...register('status')}>
          {covidStates.map(({ value, label }) => (
            <option id={value} key={value} value={value}>
              {label}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <Form.Group controlId='info'>
        <Form.Label>Detail:</Form.Label>
        <Form.Control
          as='textarea'
          name='info'
          {...register('info')}
          autoComplete='off'
        />
      </Form.Group>
      <Form.Group controlId='source'>
        <Form.Label>Source of info:</Form.Label>
        <Form.Control
          name='source'
          placeholder='https://airfield.website/'
          {...register('source')}
          autoComplete='off'
        />
        <Form.Text className='text-muted'>
          Providing an information source lets others know the veracity of the
          information and where to check for the latest info. URLs will be
          hyperlinked.
        </Form.Text>
      </Form.Group>
      <Form.Group controlId='user'>
        <Form.Label>Reported by:</Form.Label>
        <Form.Control name='user' {...register('user')} autoComplete='off' />
        <Form.Text className='text-muted'>
          Please provide some form of name to credit your work
        </Form.Text>
      </Form.Group>
      {error ? (
        <p>
          <font color='Red'>Error: {error}</font>
        </p>
      ) : (
        ''
      )}
      <ButtonLoader variant='primary' type='submit' disabled={!isDirty}>
        Save changes
      </ButtonLoader>{' '}
      <Button
        variant='secondary'
        type=''
        disabled={!isDirty && !saving}
        onClick={initForm}
      >
        Reset
      </Button>
    </Form>
  );
};

export default CovidEditForm;
