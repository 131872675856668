import { AmenityAccommodation, AmenityFood, AmenityMuseum, AmenityUnknown } from './Icons';

const amenityTypes = [
  {
    type: 'accommodation',
    name: 'Accommodation',
    icon: AmenityAccommodation
  },
  {
    type: 'food',
    name: 'Food',
    icon: AmenityFood
  },
  {
    type: 'museum',
    name: 'Museum',
    icon: AmenityMuseum
  },
  {
    type: 'generic',
    name: 'Generic amenity',
    icon: AmenityUnknown
  }
];

const amenityByType = type => {
  return amenityTypes.find(amenity => {
    return amenity.type === type;
  });
};

export { amenityByType, AmenityAccommodation, AmenityFood, AmenityMuseum, AmenityUnknown };
