import Fuse from 'fuse.js'
import Userfront from "@userfront/react";

export const API_URL = '/api/v1/';
export const API_URL_AIRFIELDS = API_URL + 'airfields/';
const API_URL_LIST = API_URL_AIRFIELDS + 'list';
const API_URL_SLUG = API_URL_AIRFIELDS + 'a/';
const API_URL_INFO = API_URL_AIRFIELDS + 'i/';
const API_URL_ENV = API_URL + 'config/env';

// Cache of airfields - download only on first load
var airfields_cache;

// Fuse search - initialised on airfield load
var fuse;

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

async function _getOrCacheAirfields() {
  if(!airfields_cache) {
    const response = await fetch(API_URL_LIST);
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    airfields_cache = await response.json();

    // Initialise Fuse search
    fuse = new Fuse(airfields_cache,
      {
        includeScore: true,
        useExtendedSearch: true,
        keys: ['name'] // TODO: add ICAO (here and in airfieldmap-server)
      });
  }

  return airfields_cache;
}

export function getAirfield(id) {
  return fetch(API_URL_INFO + id)
    .then(handleErrors)
    .then(res => res.json());
}

export function getAirfieldBySlug(slug) {
  return fetch(API_URL_SLUG + slug)
    .then(handleErrors)
    .then(res => res.json());
}

export async function getAirfields() {
  return await _getOrCacheAirfields();
}

export async function search(string) {
  return fuse.search(string);
}

export function getServerEnv() {
  return fetch(API_URL_ENV)
    .then(handleErrors)
    .then(res => res.json());
}

export function updateAirfield(airfield) {
  return fetch(API_URL_AIRFIELDS + '/update', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${Userfront.accessToken()}`,
    },
    body: JSON.stringify(airfield)
  })
    .then(handleErrors)
    .then(res => res.json());
}

export function setAirfield(airfield) {
  return fetch(API_URL_AIRFIELDS + '/replace', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${Userfront.accessToken()}`,
    },
    body: JSON.stringify(airfield)
  })
    .then(handleErrors)
    .then(res => res.json());
}

// TODO: write tests for this, if necessary?
