import React from 'react';
import Icons from './Icons';

// TODO: tidy this page up so that it looks nicer, and matches other page styles (when there is one)

export const Attribution = () => (
  <>
    <h1>Attribution</h1>
    <table className='table table-striped table-nonfluid'>
      <thead>
        <tr>
          <th>Icon</th>
          <th>Name</th>
          <th>Author</th>
          <th>Source</th>
        </tr>
      </thead>
      <tbody>
        {Icons.map(icon => {
          return (
            <tr key={icon.type ? icon.type : ''}>
              <td>
                <img className='keyIcon' src={icon.image} alt={icon.caption} />
                {icon.caption}
              </td>
              <td>
                <a href={icon.attribution.url}>{icon.attribution.name}</a>
              </td>
              <td>{icon.attribution.author}</td>
              <td>{icon.attribution.source}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  </>
);

export default Attribution;
