import React, { useState, useEffect } from 'react';
import Form from "@rjsf/bootstrap-4";
import { useParams } from 'react-router';
import { getAirfield, getAirfieldBySlug, updateAirfield } from './API';
import AirfieldCard from './AirfieldCard';
import EditJson from './EditJson.js';
import SplitPane from 'react-split-pane';
import './Resizer.css';

import { schema, uiSchema } from 'airfieldmap-common';


const AirfieldEditPage = () => {
  const { slug, onSave } = useParams();
  const [airfield, setAirfield] = useState({});

  useEffect(() => {
    if (slug) {
      getAirfieldBySlug(slug).then(data => {
        if(data) {
          setAirfield(data);
        } else {
          // Try again with ID instead of slug
          getAirfield(slug).then(data => {
            setAirfield(data);
          });
        }
      });
    }
  }, [slug]);

  // TODO: this assumes the child has provided an update including the ID,
  // it may be better for this to be done here instead.
  const handleSave = updateData => {
    return updateAirfield(updateData).then(result => {
      // Update this airfield's raw data
      setAirfield(result);
      // Tell parent data has changed (to cause map refresh)
      onSave();
    });
  };

  return (
    <SplitPane split="vertical" defaultSize="33%">
      <AirfieldCard airfield={airfield} />
      <SplitPane split="vertical" defaultSize="50%">
        <div className='myeditor'>
          <Form schema={schema} uiSchema={uiSchema} formData={airfield}/>
        </div>
        <div className='jsoneditor-column'>
          <EditJson
              json={airfield}
              onSave={handleSave}
            />
          </div>
      </SplitPane>
    </SplitPane>
  );
};

export default AirfieldEditPage;

// TODO: make the view update live as the edit validates

/*******************
 *  View  *  Edit  *
 *        *        *
 *        *        *
 *        *        *
 *******************/

// TODO: maybe implement https://atlassian.github.io/better-ajv-errors/
