import { LayerGroup, Marker } from 'react-leaflet';
import React, { useEffect, useState } from 'react';
import {
  GeoJSON,
  MapContainer,
  TileLayer,
  useMapEvents,
} from 'react-leaflet';
import { getAirfields } from './API';
import { iconByType } from './Icons';
import { amenityByType } from './Amenities';

// --- Begin workaround ---

// Workaround for "data:image/png;base64,iVBOR......5CYII=")marker-icon-2x.png net::ERR_INVALID_URL" error
// See https://github.com/PaulLeCam/react-leaflet/issues/520#issuecomment-1007096669

import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

// --- End workaround ---

// From https://stackoverflow.com/questions/44112826/delete-css-rule-inserted-with-insertrule-by-key-and-not-index
function getStyleRuleIndexBySelector(rules, selector, prop){
  var result = [], i,
      value = (prop ? selector + "{" + prop + "}" : selector).replace(/\s/g, ''), // remove whitespaces
      s = prop ? "cssText" : "selectorText";
  
  for( i=0; i < rules.length; i++ )
    if( rules[i][s].replace(/\s/g, '') === value)
      result.push(i);
      
  return result;
}

function MapEvents(props) {
  function setMarkerSize(/*map, mapMarkerStyle*/) {
    var zoomSize = 2.5 * map.getZoom();
    var markerClass = id === 'map-overview' ? '.mapMarkerOverview' : '.mapMarkerDetail';

    // TODO: should probably select a specific stylesheet rather than just the first one
    const stylesheet = document.styleSheets[0];

    var mapMarkerStyleIndices = getStyleRuleIndexBySelector(stylesheet.cssRules, markerClass);
    if(mapMarkerStyleIndices.length > 0) {
      stylesheet.deleteRule(mapMarkerStyleIndices[0]);
    }

    var rule = markerClass + '{height: ' + zoomSize + 'px; width: ' + zoomSize + 'px;}'
    stylesheet.insertRule(rule);
  }

  const id = props.id;
  const map = useMapEvents({
    load(e) {
      setMarkerSize();
    },
    zoomend() {
      setMarkerSize();
    }
  });

  return null;
}

function MapContent(props) {
  const [airfields, setAirfields] = useState([]);

  useEffect(() => {
    getAirfields().then(a => {
      // Note: this will result in a re-rendering, but it only happens once on first load
      setAirfields(a);
    });
  }, []);

  const handleMarkerClick = (event, slug) => {
    if('onSelectAirfield' in props) {
      props.onSelectAirfield(slug);
    }
  };

  if (props.airfield) {
    if (props.id === 'map-detail') { // Detail map - show local amenities
      return (
        <>
          {
            'area' in props.airfield &&
              <GeoJSON key={'area'} data={props.airfield.area} />
          }
          {
            // This was causing a "data:image/png;base64,iVBOR......5CYII=")marker-icon-2x.png net::ERR_INVALID_URL"
            // error, see workaround above.
            'centroid' in props.airfield &&
              <GeoJSON key={'centroid'} data={props.airfield.centroid} />
          }

          { // Runways
            props.airfield.runways && props.airfield.runways.map( (runway) => {
            // TODO: more styling: width; dotted centreline?
            var style = {};
              if (runway.surface === 'grass') {
                style['color']='green';
              } else if(runway.surface === 'concrete') {
                style['color']='gray';
              }
              return (<GeoJSON key={runway.name} data={runway.area} style={style}/>)
            } )
          }

          { // Amenities
            props.airfield.amenities && props.airfield.amenities
              .filter(amenity => ('centroid' in amenity))
              .map((amenity) => {
                var leaflet_icon = amenityByType(amenity.type).icon.leaflet_icon;
                leaflet_icon.options.className += ' mapMarkerDetail';
                return <Marker
                  key={amenity.name}
                  position={{
                    lng: amenity.centroid.coordinates[0],
                    lat: amenity.centroid.coordinates[1]
                  }}
                  title={amenity.name}
                  icon={leaflet_icon} />
              })
          }
        </>
      )
    } else { // Overview map - show adjacent airfields
        return ( <LayerGroup>
        {
          airfields
          .filter(amenity => ('centroid' in amenity))
          .map(airfield => {
            var leaflet_icon = iconByType(airfield.aeroway).leaflet_icon;
            leaflet_icon.options.className += ' mapMarkerOverview';
            return (
              <Marker
                key={airfield._id}
                position={{
                  lng: airfield.centroid.coordinates[0],
                  lat: airfield.centroid.coordinates[1]
                }} // TODO: consider switching this to GeoJSON?
                title={airfield.name}
                icon={leaflet_icon}
                eventHandlers={{
                  click: (event) => {
                    handleMarkerClick(event, airfield.slug || airfield._id)
                }}}
              ></Marker>
            )
          })
        }
        </LayerGroup>
      )
    }
  }

  return (<></>);
}

function MiniMap(props) {
  const id = props.id;
  const [map, setMap] = useState(null);

  if(props.airfield) {
    const location = [props.airfield.centroid.coordinates[1], props.airfield.centroid.coordinates[0]];
    if(map) {
      map.setView(location, map.getZoom());
    }
  }

  // TODO: center is immutable - need to forcibly change it
  // https://react-leaflet.js.org/docs/example-external-state
  // -> map.setView(center, zoom);

  const humanitarianTiles = '//a.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png';
  const humanitarianAttr =
    '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';
  const iconsAttr = ' | <a href="/attribution">Icons attribution</a>';

  return (
    <MapContainer
      zoom={props.id === 'map-overview' ? 10 : 14}
      className={'minimap'}
      whenCreated={setMap}
      id={id}
    >
      <MapEvents id={id}/>
          <TileLayer
            attribution={humanitarianAttr + iconsAttr}
            url={humanitarianTiles}
          />
    <MapContent {...props} />
    </MapContainer>
  );
}

export default MiniMap;
