import L from 'leaflet';

import AirportIcon from './icons/airport_icon.svg';
import AirportIconCommercial from './icons/airport_icon_commercial.svg';
import AirportIconMilitary from './icons/airport_icon_military.svg';
import AirportIconGliding from './icons/airport_icon_gliding.svg';
import AirportIconMicrolight from './icons/airport_icon_trike.svg';
import AirportIconDisused from './icons/airport_icon_disused.svg';
import AirportIconFarmStrip from './icons/airport_icon_farmstrip.svg';
import AirportIconHeliport from './icons/airport_icon_heliport.svg';
import AirportIconCovidOpen from './icons/covid_open.svg';
import AirportIconCovidRestricted from './icons/covid_restricted.svg';
import AirportIconCovidClosed from './icons/covid_closed.svg';
import AirportIconCovidUnknown from './icons/covid_unknown.svg';
import Fuel from './icons/fuel_mogas.svg';
import AmenityIconAccommodation from './icons/amenity_accommodation.svg';
import AmenityIconFood from './icons/amenity_food.svg';
import AmenityIconMuseum from './icons/amenity_museum.svg';
import AmenityIconUnknown from './icons/amenity_unknown.svg';
import FavIcon from './icons/favicon.ico';

const derived_airport = {
  name: 'Derived from Airport',
  author: 'Chanut is Industries',
  source: 'Noun Project',
  url: 'https://thenounproject.com/icon/494017/'
};

const derived_virus = {
  name: 'Derived from virus',
  author: 'Ranah Pixel Studio',
  source: 'Noun Project',
  url: 'https://thenounproject.com/icon/3244393/'
};

const AmenityAccommodation = {
  type: 'amenity_accommodation',
  caption: 'Accommodation',
  image: AmenityIconAccommodation,
  attribution: {
    name: 'Bed',
    author: 'Naomi Atkinson',
    source: 'Noun Project',
    url: 'https://thenounproject.com/icon/5634/'
  },
  showInKey: false
};

const AmenityFood = {
  type: 'amenity_food',
  caption: 'Food',
  image: AmenityIconFood,
  attribution: {
    name: 'Food',
    author: 'Gregor Cresnar',
    source: 'Noun Project',
    url: 'https://thenounproject.com/icon/750655/'
  },
  showInKey: false
};

const AmenityMuseum = {
  type: 'amenity_museum',
  caption: 'Museum',
  image: AmenityIconMuseum,
  attribution: {
    name: 'Museum',
    author: 'Wolf Böse',
    source: 'Noun Project',
    url: 'https://thenounproject.com/icon/158427/'
  },
  showInKey: false
};

const AmenityUnknown = {
  type: 'amenity_unknown',
  caption: 'Unknown amenity',
  image: AmenityIconUnknown,
  attribution: {
    name: 'House',
    author: 'fajar hasyim',
    source: 'Noun Project',
    url: 'https://thenounproject.com/icon/2801037/'
  },
  showInKey: false
};


const Icons = [
  {
    // default, also used for type: 'unknown', '', undefined
    type: 'aerodrome',
    caption: 'Airport',
    image: AirportIcon,
    attribution: {
      name: 'Airport',
      author: 'Chanut is Industries',
      source: 'Noun Project',
      url: 'https://thenounproject.com/icon/494017/'
    },
    showInKey: true
  },
  {
    type: 'cat',
    caption: 'Commercial Air Transport',
    image: AirportIconCommercial,
    attribution: {
      name: 'Airport',
      author: 'Saeful Muslim',
      source: 'Noun Project',
      url: 'https://thenounproject.com/icon/1143174/'
    },
    showInKey: true
  },
  {
    type: 'military',
    caption: 'Military',
    image: AirportIconMilitary,
    attribution: derived_airport,
    showInKey: true
  },
  {
    type: 'gliding',
    caption: 'Gliding',
    image: AirportIconGliding,
    attribution: {
      name: 'Derived from Glider',
      author: 'Grégory Montigny',
      source: 'Noun Project',
      url: 'https://thenounproject.com/icon/1840463/'
    },
    showInKey: true
  },
  {
    type: 'microlight',
    caption: 'Microlight only',
    image: AirportIconMicrolight,
    attribution: {
      name: 'Hang Gliding',
      author: 'gzz',
      source: 'Noun Project',
      url: 'https://thenounproject.com/icon/2421972/'
    },
    showInKey: true
  },
  {
    type: 'disused',
    caption: 'Disused',
    image: AirportIconDisused,
    attribution: derived_airport,
    showInKey: true
  },
  {
    type: 'strip',
    caption: 'Farm strip',
    image: AirportIconFarmStrip,
    attribution: derived_airport,
    showInKey: true
  },
  {
    type: 'heliport',
    caption: 'Heliport',
    image: AirportIconHeliport,
    attribution: {
      name: 'Helicopter',
      author: 'Stock Image Folio',
      source: 'Noun Project',
      url: 'https://thenounproject.com/icon/666852/'
    },
    showInKey: true
  },
  {
    type: 'covid_open',
    caption: 'Airfield open',
    image: AirportIconCovidOpen,
    attribution: {
      name: 'tick',
      author: 'mikicon',
      source: 'Noun Project',
      url: 'https://thenounproject.com/icon/684585/'
    },
    showInKey: true
  },
  {
    type: 'covid_restricted',
    caption: 'Airfield restricted',
    image: AirportIconCovidRestricted,
    attribution: derived_virus,
    showInKey: true
  },
  {
    type: 'covid_closed',
    caption: 'Airfield closed',
    image: AirportIconCovidClosed,
    attribution: derived_virus,
    showInKey: true
  },
  {
    type: 'covid_unknown',
    caption: 'Airfield status unknown',
    image: AirportIconCovidUnknown,
    attribution: {
      name: 'Question',
      author: 'Mani Cheng',
      source: 'Noun Project',
      url: 'https://thenounproject.com/icon/1145852/'
    },
    showInKey: true
  },
  {
    type: 'favicon',
    caption: 'Favourite icon',
    image: FavIcon,
    attribution: {
      name: 'travel',
      author: 'Maxim Basinski',
      source: 'Noun Project',
      url: 'https://thenounproject.com/icon/1292537/'
    },
    showInKey: false
  },
  {
    type: 'fuel',
    caption: 'Fuel',
    image: Fuel,
    attribution: {
      name: 'Derived from Fuel',
      author: 'Serhii Smirnov',
      source: 'Noun Project',
      url: 'https://thenounproject.com/icon/3097002/'
    },
    showInKey: false
  },
  AmenityAccommodation,
  AmenityFood,
  AmenityMuseum,
  AmenityUnknown
];

// Pre-load Leaflet Icons for each entry in Icons
Icons.forEach(icon => {
  icon.leaflet_icon = L.icon({
    iconUrl: icon.image,
    // TODO: Size and anchor may need to vary by image
    // TODO: Size (and anchor) may need to vary by zoom
    iconAnchor: [10, 27],
    className: 'mapMarker'
  });
});

// Find icon by type value
const iconByType = type => {
  if (type === '') {
    type = 'covid_unknown';
  }
  var icon = Icons.find(icon => {
    return icon.type === type;
  });

  if (!icon) {
    console.log('Missing icon for "' + type + '"');
    // default to first icon
    icon = Icons[0];
  }

  return icon;
};

export default Icons;
export { iconByType, AmenityAccommodation, AmenityFood, AmenityMuseum, AmenityUnknown };

// TODO: Replace temporary icons with standardised one - map marker with relevant icons inside
