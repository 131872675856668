import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { JsonEditor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';

import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/github';

import { setAirfield } from './API';
import { ajv, schema } from 'airfieldmap-common';

import ObjectId  from 'bson-objectid';

const EditJson = props => {
  const [json, setJson] = useState({});
  const [dirty, setDirty] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [error, setError] = useState('');
  const jsonEditorRef = useRef();

  useEffect(() => {
    // Don't load anything until there's a valid ID (not the first call)
    if(props.json) {
      setJson(props.json);
      jsonEditorRef.current.jsonEditor.set(props.json);
      setDirty(false);
    }
  }, [props.json, refresh]);

  const handleOnChange = json => {
    setJson(json);
    jsonEditorRef.current.jsonEditor.set(json);
    setDirty(true);
  };

  const handleSave = e => {
    e.preventDefault();

    // TODO: add some kind of waiting indicator
    // TODO: deal with concurrency issues if someone else has changed it!?
    //this.setState({ saving: true });

    // TODO: handle valid response, invalid response (bad validation if different from client-side), no response
    setAirfield(json)
      .then(result => {
        setTimeout(() => {
          /*this.setState({
            // TODO: need to do something with this state
            saving: false,
            saved: true
          });*/
          console.log('saved');
        }, 4000)
      }) // TODO: remove this
      .catch(reason => {
        setError(reason.toString());
      });

    setDirty(false);
  };

  const handleReset = e => {
    e.preventDefault();
    // This feels like a bit of a bodge, but this will cause useEffect to reload the original data
    setRefresh(refresh + 1);
  };

  const handleCreateNew = e => {
    e.preventDefault();
   
    handleOnChange({
      _id: ObjectId().toHexString(),
      centroid: {
        type: 'Point',
        coordinates: [0.0000000, 50.0000000]
      },
      aeroway: 'strip',
      name: 'New airfield'
    });

    // TODO: how to poke refresh of map; or add new entry to existing?
  }

  return (
    <>
      <JsonEditor
            ref={jsonEditorRef}
            value={json}
            onChange={handleOnChange}
            mode='code'
            allowedModes={['tree', 'form', 'view', 'code', 'text']}
            statusBar={true}
            search={true}
            ace={ace}
            ajv={ajv}
            theme="ace/theme/github"
            schema={schema}
            htmlElementProps={{className: 'jsoneditor-container'}}
        />
      <div className="jsoneditor-controls">
      <Button
        className='m-1'
        color='primary'
        disabled={!dirty}
        onClick={handleSave}
        type='submit'
      >
        Save changes
      </Button>
      <Button
        className='m-1'
        color='secondary'
        disabled={!dirty}
        onClick={handleReset}
        type='reset'
      >
        Reset
      </Button>
      <Button
        className='m-1'
        color='primary'
        disabled={dirty || error}
        onClick={handleCreateNew}
      >
        Create new
      </Button>
      </div>
    </>
  );
};

export default EditJson;

// TODO: share validation with server-end -> ajvschema
// TODO: warn user of validation errors, from the server-end
// TODO: catch and deal with change while dirty - modal dialog?

// TODO: Look into https://ismaelga.github.io/react-json-editor/ for form generation
